import "./App.css";
import axios from "axios";
import { useState, useEffect } from "react";
import { Table, Button, Input } from "antd";
import { Pagination } from "antd";
import moment from "moment";
// const URL = "https://api.cash-5656.com";
const URL = "https://mainapi.enjoy-7979.com";

// const URL = "https://api.jackpot-101.com";

// const adminname = 'woongpay'
// const URL = "http://localhost:2009";

const API = axios.create({
  baseURL: URL,
});
function App() {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const { Search } = Input;
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loadingWithdraw, setLoadingWithdraw] = useState(false);
  const [loadingSeach, setLoadingSearch] = useState(false);
  const [totalData, setTotalData] = useState(0);

  const [username, setUsername] = useState('')
  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const columns = [
    {
      title: "ID",
      dataIndex: "username",
      align: "left",
    },

    {
      title: "보유금액",
      dataIndex: "balance",
      render: (row) => row?.toLocaleString(),
      align: "center",
    },
    {
      title: "생성일자",
      dataIndex: "createdAt",
      render: (row) => moment(row).format("YYYY-MM-DD HH:mm"),
      align: "center",
    },
  ];

  const getValue = async (params) => {
    try {
      setLoading(true);
      const res = await API.post("/getListUSer", {
        ...params,
      });
      const {  data, count, list } = res.data;
      const main = data?.map((item, index) => {
        return {
          ...item,
          balance: item.balance + (list?.find(us => us.username === item.username)?.balance || 0),
          key: item.username,
        };
      });
      setData(main);
      setCount(count);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  const getTotalAmount = async () => {
    try {
      const {data}  = await API.post('/getTotalAmount')
      setTotalData(data)

    } catch (error) {
      
    }
  }
  const handleDelete = async () => {
    try {
      setLoadingDelete(true);

      await API.post("/deleteAll", {
        usernames: selectedRowKeys,
      });

      const filteredUsers = data.filter(
        (user) => !selectedRowKeys.includes(user.username)
      );
      setData([...filteredUsers]);
      setLoadingDelete(false);
    } catch (error) {
      setLoadingDelete(false);

      console.log(error);
    }
  };

  const withdrawAll = async () => {
    try {
      setLoadingWithdraw(true);
      await API.post("/withdrawAll", {
        username: selectedRowKeys,
      });

      setData((prevUsers) =>
        prevUsers.map((user) => {
          if (selectedRowKeys.includes(user.username)) {
            return { ...user, balance: 0 };
          }
          return user;
        })
      );
      setLoadingWithdraw(false);
      setSelectedRowKeys([]);
    } catch (error) {
      setLoadingWithdraw(false);

      console.log(error);
    }
  };
  const onSearch =  (username) => {
    getValue({
      page: 1,
      limit: 10,
      username
    })
    setPage(1)
  }
  useEffect(() => {
    getValue({
      page: page,
      limit: 10,
      username
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);
  useEffect(() => {
    getTotalAmount()
  }, [])
  return (
    <div className="App">
      <div
        style={{
          display: "flex",
          gap: 10,
          margin: 2,
          justifyContent: "space-between",
        }}
      >
        <div
          className
          style={{
            display: "flex",
            gap: 10,
            margin: 2,
          }}
        >
          <Button onClick={handleDelete} loading={loadingDelete}>
            삭제
          </Button>
          <Button onClick={withdrawAll} loading={loadingWithdraw}>
            회수
          </Button>
        </div>
        {/* <>
        총보유금 : {Number(totalData.toFixed(0)).toLocaleString("en-US")}
        </> */}
        <Search
          placeholder="아이디 입력하세요"
          loading={loadingSeach}
          onSearch={onSearch}
          value={username}
          onChange={(val) => setUsername(val.target.value)}
          style={{
            width: 200,
          }}

        />
      </div>
      <Table
        rowSelection={rowSelection}
        columns={columns}
        dataSource={data}
        footer={false}
        pagination={false}
        loading={loading}
      />
      <div className="main">
        <Pagination
          defaultCurrent={page}
          page={page}
          total={count}
          onChange={(_page) => setPage(_page)}
        />
      </div>
    </div>
  );
}

export default App;
